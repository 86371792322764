<template>
  <v-main
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-img
      :class="{
        'v-image--sm': this.$vuetify.breakpoint.smAndDown,
        'v-image--md': this.$vuetify.breakpoint.mdAndDown
      }"
      :src="require(`@/assets/${src || 'login.jpg'}`)"
    >
      <v-responsive :style="styles" class="d-flex align-center">
        <router-view />
      </v-responsive>
    </v-img>
  </v-main>
</template>

<script>
export default {
  name: "PagesCoreView",

  data: () => ({
    srcs: {
      "/pages/lock": "lock.jpg",
      "/pages/login": "login.jpg",
      "/pages/pricing": "pricing.jpg",
      "/pages/register": "register.jpg"
    }
  }),

  computed: {
    src() {
      return this.srcs[this.$route.path];
    },
    styles() {
      const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100;
      const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150;
      return {
        overflow: "hidden",
        padding: `${paddingTop}px 0 ${paddingBottom}px 0`
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
